<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text class="py-0">
        <v-form
          v-model="isFormValid"
          ref="parentesco-sss-form"
          form="parentesco-sss-form"
          id="parentesco-sss-form"
          @submit.prevent="saveParentesco()"
        >
          <v-row>
            <v-col cols="12" md="12" class="pb-0">
              <v-text-field
                v-model.trim="nombreParentesco"
                label="Nombre"
                type="text"
                dense
                autocomplete="off"
                outlined
                :rules="rules.required.concat([
                  rules.requiredTrim(nombreParentesco),
                  rules.maxLength(nombreParentesco, 50)
                ])"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pb-0 pt-2">
              <v-text-field
                v-model="sssCodigo"
                label="Código SSS"
                dense
                onkeydown="return (event.keyCode !== 32)"
                outlined
                :rules="
                  sssCodigo === 0
                    ? []
                    : rules.required.concat([rules.maxLength(sssCodigo, 3)])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" class="pb-0 pt-2">
              <v-autocomplete
                ref="parentesco-sss-autocomplete"
                :items="parentescos"
                v-model="parentescoSelected"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                outlined
                clearable
                dense
                autocomplete="off"
                label="Parentesco"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pb-0 pt-2">
              <v-text-field
                v-model="edadDesde"
                label="Edad desde"
                dense
                outlined
                v-mask="'###'"
                :rules="
                  edadDesde === 0
                    ? []
                    : rules.required.concat([
                        rules.maxNumber(edadDesde, 125),
                        rules.validDateRange(
                          parseInt(edadDesde),
                          parseInt(edadHasta)
                        )
                      ])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pb-0 pt-2">
              <v-text-field
                v-model="edadHasta"
                label="Edad hasta"
                dense
                outlined
                v-mask="'###'"
                :rules="
                  edadHasta === 0
                    ? []
                    : rules.required.concat([
                        rules.maxNumber(edadHasta, 125),
                        rules.validDateRange(
                          parseInt(edadDesde),
                          parseInt(edadHasta)
                        )
                      ])
                "
              ></v-text-field>
            </v-col>
            <!-- Incapacidad -->
            <v-col cols="12" sm="6" md="2" class="py-0">
              <v-switch
                class="py-0"
                v-model="incapacidad"
                label="Incapacidad"
                dense
                outlined
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-spacer class="py-0"></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="parentesco-sss-form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <ConfirmDialog
        :text="textConfirmDialog"
        :openConfirm.sync="openConfirmDialog"
        @onConfirm="saveParentesco()"
        @update:openConfirm="cancelarConfirmDialog"
        :submitBtn="'GUARDAR'"
        :cancelBtn="'EDITAR'"
      />
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import { mask } from "vue-the-mask";

export default {
  name: "EditParentescoPadronMensual",
  components: { ConfirmDialog },
  directives: { mask },

  props: {
    idParentescoToEdit: { type: Number, required: false, default: null },
    arrayToCheckCodigos: { type: Array }
  },
  data: () => ({
    isFormValid: false,
    formTitle: "Nuevo parentesco",
    rules: rules,
    nombreParentesco: null,
    sssCodigo: null,
    edadDesde: null,
    edadHasta: null,
    incapacidad: false,
    parentescoSelected: null,
    parentescos: [],
    openConfirmDialog: false,
    textConfirmDialog: ""
  }),
  created() {
    this.setParentescosCombo();
    if (this.idParentescoToEdit !== null) {
      this.setParentesco();
    }
  },
 
  methods: {
    ...mapActions({
      getParentescosSSSById: "configAfiliaciones/getParentescosSSSById",
      postParentescosSSS: "configAfiliaciones/postParentescosSSS",
      getParentescos: "configAppBenef/getParentescos",
      setAlert: "user/setAlert"
    }),

    async setParentescosCombo() {
      this.parentescos = await this.getParentescos();
    },
    async checkSameCodigoValue(codigo, array) {
      const sameCodigo = array.find(
        x => x.sssParCod == codigo && this.idParentescoToEdit != x.sssParId
      );
      if (sameCodigo != undefined) {
        this.openConfirmDialog = true;

        this.textConfirmDialog = `El registro "${sameCodigo.sssParNombre}" posee el mismo código SSS, ¿Desea guardar de todas formas?`;
      } else {
        this.saveParentesco();
      }
    },
    async setParentesco() {
      this.formTitle = "Editar parentesco";
      const response = await this.getParentescosSSSById({
        id: this.idParentescoToEdit
      });
      this.nombreParentesco = response.sssParNombre;
      this.sssCodigo = response.sssParCod;
      this.edadDesde = response.edadDesde;
      this.edadHasta = response.edadHasta;
      this.parentescoSelected = response.parId;
      this.incapacidad = response.incapacidad;
    },

    cancelarConfirmDialog() {
      this.textConfirmDialog = "";
    },

    async saveParentesco() {
      const data = {
        sssParId: this.idParentescoToEdit,
        sssParNombre: this.nombreParentesco,
        sssParCod: this.sssCodigo,
        edadDesde: this.edadDesde,
        edadHasta: this.edadHasta,
        parId: this.parentescoSelected,
        incapacidad: this.incapacidad
      };
      const response = await this.postParentescosSSS(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeAndReload();
      }
    },
    closeAndReload() {
      this.$emit("closeModalsAndSetTables");
    },
    
    closeModal() {
      this.$emit("toggleModalEditParentesco");
    }
  }
};
</script>

<style></style>
