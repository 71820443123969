var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',{staticClass:"py-0"},[_c('v-form',{ref:"parentesco-sss-form",attrs:{"form":"parentesco-sss-form","id":"parentesco-sss-form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveParentesco()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre","type":"text","dense":"","autocomplete":"off","outlined":"","rules":_vm.rules.required.concat([
                _vm.rules.requiredTrim(_vm.nombreParentesco),
                _vm.rules.maxLength(_vm.nombreParentesco, 50)
              ])},model:{value:(_vm.nombreParentesco),callback:function ($$v) {_vm.nombreParentesco=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombreParentesco"}})],1),_c('v-col',{staticClass:"pb-0 pt-2",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Código SSS","dense":"","onkeydown":"return (event.keyCode !== 32)","outlined":"","rules":_vm.sssCodigo === 0
                  ? []
                  : _vm.rules.required.concat([_vm.rules.maxLength(_vm.sssCodigo, 3)])},model:{value:(_vm.sssCodigo),callback:function ($$v) {_vm.sssCodigo=$$v},expression:"sssCodigo"}})],1),_c('v-col',{staticClass:"pb-0 pt-2",attrs:{"cols":"12","md":"8"}},[_c('v-autocomplete',{ref:"parentesco-sss-autocomplete",attrs:{"items":_vm.parentescos,"item-text":"value","item-value":"id","rules":_vm.rules.required,"outlined":"","clearable":"","dense":"","autocomplete":"off","label":"Parentesco"},model:{value:(_vm.parentescoSelected),callback:function ($$v) {_vm.parentescoSelected=$$v},expression:"parentescoSelected"}})],1),_c('v-col',{staticClass:"pb-0 pt-2",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"label":"Edad desde","dense":"","outlined":"","rules":_vm.edadDesde === 0
                  ? []
                  : _vm.rules.required.concat([
                      _vm.rules.maxNumber(_vm.edadDesde, 125),
                      _vm.rules.validDateRange(
                        parseInt(_vm.edadDesde),
                        parseInt(_vm.edadHasta)
                      )
                    ])},model:{value:(_vm.edadDesde),callback:function ($$v) {_vm.edadDesde=$$v},expression:"edadDesde"}})],1),_c('v-col',{staticClass:"pb-0 pt-2",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"label":"Edad hasta","dense":"","outlined":"","rules":_vm.edadHasta === 0
                  ? []
                  : _vm.rules.required.concat([
                      _vm.rules.maxNumber(_vm.edadHasta, 125),
                      _vm.rules.validDateRange(
                        parseInt(_vm.edadDesde),
                        parseInt(_vm.edadHasta)
                      )
                    ])},model:{value:(_vm.edadHasta),callback:function ($$v) {_vm.edadHasta=$$v},expression:"edadHasta"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-switch',{staticClass:"py-0",attrs:{"label":"Incapacidad","dense":"","outlined":""},model:{value:(_vm.incapacidad),callback:function ($$v) {_vm.incapacidad=$$v},expression:"incapacidad"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer',{staticClass:"py-0"}),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"parentesco-sss-form","color":"primary"}},[_vm._v(" Guardar ")])],1),_c('ConfirmDialog',{attrs:{"text":_vm.textConfirmDialog,"openConfirm":_vm.openConfirmDialog,"submitBtn":'GUARDAR',"cancelBtn":'EDITAR'},on:{"update:openConfirm":[function($event){_vm.openConfirmDialog=$event},_vm.cancelarConfirmDialog],"update:open-confirm":function($event){_vm.openConfirmDialog=$event},"onConfirm":function($event){return _vm.saveParentesco()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }